/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  useState,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import styles from '@/interface/ui/_shared/items/calenderButtonGroup.module.scss';
import iCal from '@/interface/ui/_shared/calender/iCal';
import googleCal from '@/interface/ui/_shared/calender/googleCal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Launch from '@/domain/launch';
import colors from '@/constants/colors';
import type Event from '@/domain/events/index';
import ItemDetail from '@/domain/item_detail';

type Props = {
  item: Event | Launch | ItemDetail;
  userAgent?: string;
  calTitle?: string;
  buttonStyle?: string;
  position?: 'left' | 'right';
};

const CalenderButtonGroup = (
  { item, userAgent, calTitle, buttonStyle, position }: Props,
  ref
) => {
  const userAgents: string = userAgent || '';
  const title: string = item.calendarTitle || item.title;
  const startDate: Date = item.calendarStartAt || new Date(item.releaseAt);
  const endDate: Date = item.calendarEndAt || getEndDate(startDate);
  const [showPopOver, setShowPopOver] = useState(false);
  const [isHoverIcal, setHoverIcal] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const togglePopOver = useCallback(() => {
    setShowPopOver(!showPopOver);
  }, [setShowPopOver, showPopOver]);

  const hoverIcal = () => {
    setHoverIcal(true);
  };

  const removeHover = () => {
    setHoverIcal(false);
  };

  useImperativeHandle(
    ref,
    () => ({
      togglePopOver,
    }),
    [togglePopOver]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        showPopOver
      ) {
        setShowPopOver(false);
      }
    },
    [showPopOver]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  const renderPopOver = () => {
    const customStyle = {
      left:
        position === 'left' ? '-120px' : position === 'right' ? '90px' : '0px',
    };
    return (
      <div
        className={
          isHoverIcal ? styles.popover__contents : styles.popover__content
        }
        ref={wrapperRef}
        style={customStyle}
      >
        {isIOSorMacOS(userAgents) &&
          iCal(title, startDate, endDate, hoverIcal, removeHover)}
        {googleCal(title, startDate, endDate)}
      </div>
    );
  };

  return (
    <div className={styles.popover__wrapper} onClick={togglePopOver}>
      {calTitle ? (
        <p className={styles.button}>{calTitle}</p>
      ) : (
        <FontAwesomeIcon
          icon="calendar-alt"
          color={colors.lightGray}
          size="lg"
          className={buttonStyle || styles.calendarIcon}
        />
      )}
      {showPopOver && renderPopOver()}
    </div>
  );
};

const isIOSorMacOS = function (userAgent: string): boolean {
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  return Boolean(userAgent.match(/iPhone|iPad|iPod|Mac OS/i));
};

function getEndDate(date: Date): Date {
  date.setHours(date.getHours() + 3);
  return date;
}

export default forwardRef(CalenderButtonGroup);
