import styles from '@/interface/ui/_shared/calender/index.module.css';

const iCal = (
  title: string,
  startDate: Date,
  endDate: Date,
  hoverIcal: () => void,
  removeHover: () => void
): JSX.Element => {
  return (
    <div
      className={styles.contenWrapper}
      onMouseEnter={hoverIcal}
      onMouseLeave={removeHover}
    >
      <div
        className={styles.contents}
        onClick={() => save(title, startDate, endDate)}
      >
        <span className={styles.caption}>iCal </span>
      </div>
    </div>
  );
};

function save(itemTitle: string, startDate: Date, endDate: Date) {
  // name of event in iCal
  const eventName = `${itemTitle}`;

  // time of event in iCal
  const now = new Date();

  //helper functions
  const icsLines = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//atmos//atmos-list//JA',
    'METHOD:REQUEST',
    'BEGIN:VEVENT',
    `UID:event-${toISO(now)}`,
    `DTSTAMP:${toISO(now)}`,
    `DTSTART:${toISO(startDate)}`,
    `DTEND:${toISO(endDate)}`,
    `SUMMARY:${eventName}`,
    `LAST-MODIFIED:${toISO(now)}`,
    'SEQUENCE:0',
    'END:VEVENT',
    'END:VCALENDAR',
  ];

  try {
    const downloadUrl = `data:text/calendar;base64,${btoa(
      unescape(encodeURIComponent(icsLines.join('\r\n')))
    )}`;
    saveFile(downloadUrl, eventName);
  } catch (e) {
    alert('カレンダーの登録に失敗しました。');
  }
}

const saveFile = function (fileURL: string, eventName: string) {
  //name of file to download as
  const fileName = eventName;

  const save = document.createElement('a');
  save.href = fileURL;
  save.target = '_blank';
  save.download = fileName;

  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  });
  save.dispatchEvent(event);

  (window.URL || window.webkitURL).revokeObjectURL(save.href);
};

function toISO(date: Date) {
  return date.toISOString().replace(/-|:|\.\d\d\d/g, '');
}

export default iCal;
