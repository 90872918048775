const colors = {
  white: '#ffffff',
  black: '#111111',
  lightGray: '#ddd',
  background: '#f7f7f7',
  gray: '#777677',
  gray_1: '#333',
  gray_2: '#777',
  blue: '#1E349B',
  lightBlue: '#8098CE',
  gold: '#B19B5C',
  silver: '#777677',
  platinum: '#787879',
  red: 'red',
  deepBlack: '#000000',
  diamond: '#C6AB54',
};

export default { ...colors };
