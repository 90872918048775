import styles from '@/interface/ui/_shared/calender/index.module.css';

const googleCal = (
  title: string,
  startDate: Date,
  endDate: Date
): JSX.Element => {
  return (
    <div className={styles.contenWrapper}>
      <a
        className={styles.contents}
        href={saveLink(`${title}`, startDate, endDate)}
        target="_blank"
        rel="noreferrer"
      >
        <span className={styles.caption}>Googleカレンダー </span>
      </a>
    </div>
  );
};

function saveLink(itemTitle: string, startDate: Date, endDate: Date) {
  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${itemTitle}&dates=${toISO(
    startDate
  )}/${toISO(endDate)}`;
}

function toISO(date: Date | string) {
  return new Date(date).toISOString().replace(/-|:|\.\d\d\d/g, '');
}

export default googleCal;
